<template>
  <div style="padding: 20px">
    <h2 class="text-h5 mb-4">Orders</h2>

    <v-dialog v-model="order_detail_dialog" scrollable max-width="800px">
      <v-card style="padding: 20px; height: 100%">
        <v-tabs v-model="detail_tabs">
          <v-tab href="#tab-1"> Raw Etsy Data </v-tab>

          <v-tab href="#tab-2"> Raw Shopify Data </v-tab>
        </v-tabs>
        <v-tabs-items v-model="detail_tabs">
          <v-tab-item :key="1" value="tab-1">
            <div v-if="fetching_order_data" style="padding: 60px 0px" class="text-center">
              <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
            </div>
            <div v-else>
              <div class="mt-5 mb-5 text-center" v-if="!order_data.success">
                <h2>Something went wrong!!</h2>
              </div>
              <div v-else>
                <json-viewer :value="order_data.response" copyable expanded></json-viewer>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item :key="2" value="tab-2">
            <div class="text-center" style="padding: 100px 0px" v-if="active_order.shopify_order_id == 0">
              <h4>Not Posted to Shopify yet.</h4>
            </div>
            <div v-else>
              <json-viewer
                v-if="active_order.shopify_order_result"
                :value="JSON.parse(active_order.shopify_order_result)"
                copyable
                expanded
              ></json-viewer>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>

    <v-data-table :headers="headers" :items="orders.data" class="elevation-1" hide-default-footer>
      <template v-slot:top>
        <v-row style="padding: 10px">
          <v-col md="2" cols="6"> <strong>Total: </strong> {{ orders.total }} </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="search_keyword"
              label="Search Keyword"
              outlined
              dense
              placeholder="Search Keyword"
              @change="searchOrders()"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="order_date"
              label="Order Date"
              outlined
              type="date"
              dense
              placeholder="Order Date"
              @input="searchOrders()"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-select
              v-model="order_state"
              dense
              @change="searchOrders()"
              :items="['All', 'Successful', 'Failed']"
              label="Order State"
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:body="{ items }">
        <tbody v-if="!is_loading">
          <tr v-if="!items.length">
            <td colspan="8" class="text-center" style="padding: 20px 0px">
              <img :src="require('@/assets/images/misc/no-data.png')" alt="" width="250px" />
              <p style="font-size: 25px">No Data Found!!</p>
            </td>
          </tr>
          <tr v-else v-for="item in items" :key="item.id" :set="(buyer_details = JSON.parse(item.buyer_details))">
            <td>
              <i class="primary--text">#{{ item.receipt_id }}</i> &nbsp; &nbsp; ({{ item.order_sync_status }})
            </td>
            <td v-html="renderShopifyOrderResult(item)"></td>

            <td>{{ item.creation_tsz | date_format('MMM Do, Y') }}</td>
            <td>
              <v-chip small class="ma-2" color="green" text-color="white" v-if="item.was_paid == '1'">Paid</v-chip>
              <v-chip small class="ma-2" color="red" text-color="white" v-else>Not Paid</v-chip>
            </td>
            <td>
              <v-chip small class="ma-2" color="green" text-color="white" v-if="item.was_shipped == '1'"
                >Completed</v-chip
              >
              <v-chip small class="ma-2" color="red" text-color="white" v-else>Unshipped</v-chip>
              <!-- <v-chip small class="ma-2" color="red" text-color="white" v-else-if="item.status.toLowerCase() == 'cancelled'">Cancelled</v-chip>
              <v-chip small class="ma-2" color="secondary" text-color="white" v-else>Open</v-chip> -->
            </td>
            <td>
              {{ buyer_details.name }}
            </td>
            <td>{{ buyer_details.currency_code }} &nbsp; {{ parseFloat(item.price).toFixed(2) }}</td>
            <td>
              <v-btn
                v-if="!item.shopify_order_id"
                color="success"
                @click="makeOrderSyncable(item)"
                class="tet-white mb-1 btn-xs mt-3"
                small
                >Make Syncable</v-btn
              >
              <br>
              <v-btn color="primary" @click="getOrderDetails(item)" dark class="text-white mb-1 btn-xs" small
                >View Details</v-btn
              >
              <v-btn
               v-if="!item.shopify_order_id"
               color="red" @click="forceSyncOrder(item)" dark class="text-white mb-3 btn-xs" small
                >Force Sync</v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8" class="text-center" style="padding: 80px 0px">
              <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-pagination
      v-model="orders.current_page"
      class="my-4"
      @input="handlePageChange"
      :length="orders.last_page"
      :total-visible="7"
    ></v-pagination>
  </div>
</template>
<script>
import store from '@/store'
import JsonViewer from 'vue-json-viewer'
import { mdiMagnify } from '@mdi/js'
export default {
  data() {
    return {
      shop: null,
      is_loading: false,
      orders: {
        current_page: 1,
        data: [],
      },
      search_keyword: '',
      order_date: '',
      order_state: 'All',
      headers: [
        {
          text: 'Receipt ID',
          sortable:false
        },
        {
          text: 'Remarks',
          sortable:false
        },
        {
          text: 'Order Date',
          sortable:false
        },
        {
          text: 'Payment',
          sortable:false
        },
        {
          text: 'Status',
          sortable:false
        },
        {
          text: 'Customer',
          sortable:false
        },
        {
          text: 'Total',
          sortable:false
        },

        {
          text: 'Actions',
          sortable:false
        },
      ],

      order_detail_dialog: false,
      fetching_order_data: false,
      order_data: {},
      mdiMagnify,
      detail_tabs: null,
      active_order: {},

      fetch_order_dialog: false,
      fetch_receipt_id: '',
    }
  },
  components: {
    JsonViewer,
  },
  mounted() {
    this.shop = store.getters.loggedInShop
    this.fetchOrders()
  },
  methods: {
    async fetchOrders() {
      try {
        this.is_loading = true
        let response = await axios.get(`admin/shops/${this.shop.id}/get-etsy-orders`, {
          params: {
            page: this.orders.current_page,
            keyword: this.search_keyword,
            order_date: this.order_date,
            order_state: this.order_state
          },
        })

        if (response.data.success) {
          this.orders = response.data.orders
        }
      } catch (e) {
      } finally {
        this.is_loading = false
      }
    },

    handlePageChange(value) {
      this.fetchOrders()
    },

    searchOrders() {
      this.orders.current_page = 1
      this.fetchOrders()
    },

    async getOrderDetails(order) {
      try {
        this.active_order = order
        this.order_detail_dialog = true
        this.fetching_order_data = true
        let response = await axios.get(`admin/shops/${this.shop.id}/order/${order.receipt_id}/get-raw-data`)
        // if (response.data.success) {
        this.order_data = response.data
        // }
      } catch (e) {
      } finally {
        this.fetching_order_data = false
      }
    },

    async makeOrderSyncable(order) {
      try {
        let response = await axios.get(`admin/shops/${this.shop.id}/order/${order.receipt_id}/make-eligible-to-send`)
        this.fetchOrders()
      } catch (e) {}
    },

    async forceSyncOrder(order) {
      try {
        let response = await axios.post(`admin/shops/${this.shop.id}/order/${order.receipt_id}/force-sync`,{})
         if (response.data.success) {
            this.$swal.fire({
              icon: "success",
              title: "DONE",
              text: "Order has been queued for Force Sync!",
          });
          } else {
              this.$swal.fire({
                icon: "error",
                title: "OOPS...",
                text: "Error while force syncing!, Order may be already in force sync queue!",
              }); 
          }
        } catch (e) {}
    },

    renderShopifyOrderResult(order) {
      let info_text = null
      let order_status_url = false
      let order_id = ''
      let parsed_shopify_order_result = {}
      if (order.shopify_order_result) {
        parsed_shopify_order_result =
          typeof order.shopify_order_result == 'string'
            ? JSON.parse(order.shopify_order_result)
            : order.shopify_order_result

        order_status_url = parsed_shopify_order_result.order_status_url
        info_text = parsed_shopify_order_result.message
        if (!info_text) {
          info_text =
            'Order is fetched from Etsy. Our system is processing this order, and it will be sent over to Shopify in a few minutes.'
        }
        const errorMessages = [
          'Country/region not supported',
          'Enter an address',
          'Enter a postal code', 
          'Enter a city',
          'Enter a phone number'
        ];

        if (info_text) {
          if (Array.isArray(info_text)) {
            if (info_text.some(text => errorMessages.some(msg => text.includes(msg)))) {
              return 'Order update failed! Etsy restricts API access for shops based in the US, Canada, and Türkiye when using third-party apps.';
            }
          } else if (typeof info_text === 'string') {
            if (errorMessages.some(msg => info_text.includes(msg))) {
              return 'Order update failed! Etsy restricts API access for shops based in the US, Canada, and Türkiye when using third-party apps.';
            }
          }
        }
        if (order_status_url) {
          order_id =
            '<a class="dpl_link_text_green" target="_blank" href="' + order_status_url + '">View in Shopify</a>'
        }
      }
      if (order.order_sync_status == '0' && parseInt(order.was_paid) == 1) {
        if (info_text == null) {
          info_text =
            'Order is fetched from Etsy. Our system is processing this order, and it will be sent over to Shopify in a few minutes.'
        }
        return '<p>' + info_text + '</p>'
      } else if (order.order_sync_status == '-1') {
        return (
          "<p class='dpl_error_text'>" +
          (parsed_shopify_order_result.message ||
            'Congratulations! This order has been successfully sent to Shopify for fulfillment.') +
          '</p>' +
          order_id
        )
      } else if (order.order_sync_status == '-7') {
        return (
          "<p class='dpl_error_text'>You have not provided tracking number in Shopify. Tracking number is mandatory for Etsy to fulfill the order. <a class='dpl_link_text_green' target='_blank' href='https://www.etsy.com/your/orders/sold/completed?order_id=" +
          order.receipt_id +
          "'>Click here to fulfil this order.</a></p>"
        )
      } else if (order.order_sync_status == '-2') {
        return (
          '<p class="dpl_error_text">' +
          (parsed_shopify_order_result.message || 'Order post failed due to inventory issue') +
          '</p>' +
          order_id
        )
      } else if (order.order_sync_status == '2') {
        return (
          '<p>' +
          (parsed_shopify_order_result.message ||
            'Congratulations! This order has been successfully sent to Shopify for fulfillment.') +
          '</p>' +
          order_id
        )
      } else if (order.order_sync_status == '3') {
        return '<p>Order is being processed by the system.</p>'
      } else if (order.order_sync_status == '4' || parseInt(order.was_paid) == 0) {
        return '<p>This order has been placed from Etsy but is awaiting payment. Once the payment has been made, this order will be sent to Shopify for the next step.</p>'
      } else if (order.order_sync_status == '7') {
        info_text = parsed_shopify_order_result.message || 'Inventory Synced'
        return '<p>' + info_text + '</p>'
      } else {
        if (order.order_sync_status == '0' || order.order_sync_status == undefined) {
          return '<p>Processing...</p>'
        } else {
          return '<p>Congratulations! This order has been successfully sent to Shopify and is fulfilled.</p>' + order_id
        }
      }
    },
  },
}
</script>